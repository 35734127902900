import { CssBaseline, Container } from "@mui/material"
import { ThemeProvider } from "@mui/material/styles"
import PropTypes from "prop-types"
import Viewport from "./viewport"

const TopLayout = ({ children, theme }) => (
  <>
    <Viewport />
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />

      <main>
        <Container maxWidth="sm">{children}</Container>
      </main>
    </ThemeProvider>
  </>
)

TopLayout.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.object.isRequired,
}

export default TopLayout
