import { Helmet } from "react-helmet"
import PropTypes from "prop-types"

export default function Viewport({ children }) {
  return (
    <Helmet>
      <meta name="viewport" content="initial-scale=1, width=device-width" />
      {children}
    </Helmet>
  )
}

Viewport.propTypes = {
  children: PropTypes.any,
}
